<template>
  <div>
    <div class="bannerBox">
      <el-carousel
        :interval="5000"
        ref="carousels"
        indicator-position="none"
        arrow="never"
        @change="swiperChange"
      >
        <el-carousel-item height="650" v-for="item in bannerImg" :key="item.id">
          <img :src="item.img" />
        </el-carousel-item>
      </el-carousel>

      <div class="arrow"></div>
      <div class="circle-dots" v-if="bannerImg.length > 1">
        <div
          class="circle-dot"
          @click="handleChangeSwiper(index)"
          :class="currentSwiperIndex === index ? 'active' : ''"
          v-for="(dot, index) in bannerImg"
          :key="dot.id"
        ></div>
      </div>
    </div>

    <div class="steps">
      <div class="steps_head">
        <div class="w steps_head-content">
          <div class="steps_head-card" v-for="item in canList" :key="item.id">
            <img :src="item.url" alt="" />
            <div class="steps_head-card-content">
              <p class="name">{{ item.name }}</p>
              <p class="desc">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="steps_content">
        <div class="heads">
          <h1>我们能做什么</h1>
          <p>
            恒速科技多年的从业经验，积累了丰富的行业资源，为每一位选择我们的客户提供各类需求最优的
            <br />
            方案设计、产品研发、推广运维等系列互联网技术服务，坐享高效、智能、安全、可靠。
          </p>
        </div>
        <div class="w steps_content-cards">
          <div
            class="steps_content-card"
            v-for="(item, index) in doList"
            :key="item.id"
            @mouseenter="handleMouseEnter(index)"
            @mouseleave="handleMouseLeave(index)"
          >
            <img
              :src="isHover && hoverIndex === index ? item.hoverUrl : item.url"
              alt=""
            />
            <div>
              <p class="name">{{ item.name }}</p>
              <p
                class="desc"
                v-for="(str, index) in item.desc.split(' ')"
                :key="index"
              >
                {{ str }}
              </p>
            </div>
          </div>
        </div>
        <button class="more" @click="handleToCUstomCase">查看更多</button>
      </div>
    </div>

    <div class="productBox">
      <div class="heads">
        <p class="title">核心产品</p>
        <p class="subtitle">
          恒速科技基于多年的服务经验沉淀，重点打造了规范化解决相关行业问题的产品，为您解决困扰痛点的同时智享便利。
        </p>
      </div>
      <div class="w productBox_parts">
        <div class="part left_angle" @click="handleSwiperChange('left')">
          <img src="../../../src/assets/imgs/Home/left.png" alt="" />
        </div>
        <div class="center">
          <el-carousel
            ref="carousel"
            indicator-position="none"
            arrow="never"
            :interval="7000"
          >
            <el-carousel-item v-for="item in staticProducts" :key="item.id">
              <div class="carousel">
                <div class="carousel_left">
                  <h1>{{ item.projectName }}</h1>
                  <div class="dots">
                    <div class="carousel_dots">
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                    </div>
                    <div class="carousel_dots">
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                    </div>
                    <div class="carousel_dots">
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                    </div>
                    <div class="carousel_dots">
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                      <div class="carousel_dot"></div>
                    </div>
                  </div>
                  <div class="carousel_desc">
                    {{ item.desc ? item.desc : '' }}
                  </div>
                  <div class="carousel_btn" @click="handleToDetail(item)">
                    查看更多
                  </div>
                </div>
                <div class="carousel_right">
                  <!-- <img v-lazy="item.projectDetailImg" alt="" /> -->
                  <img :src="item.projectDetailImg" alt="" />
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="part right_angle" @click="handleSwiperChange('right')">
          <img src="../../../src/assets/imgs/Home/right.png" alt="" />
        </div>
      </div>
    </div>

    <div class="industry">
      <div class="heads">
        <p class="title">覆盖行业</p>
        <p class="subtitle">
          恒速科技的数字化服务覆盖了制造业、金融、教育、零售等多个行业和领域，
          <br />
          为客户提供了高效、智能、创新的数字化解决方案，解决了客户实际运营中的出现的困难。
        </p>
      </div>
      <div class="w contents">
        <div class="content" v-for="item in industry" :key="item.id">
          <div class="content-top">
            <div class="content-top_left">
              <img :src="item.url" alt="" />
            </div>
            <div class="content-top_right">
              {{ item.name }}
            </div>
          </div>
          <div class="content-bottom">
            <!-- beyondHidden -->
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="scheme">
      <div class="heads">
        <p class="title">解决方案</p>
        <p class="subtitle">
          恒速科技立足于客户需求，提供满足客户需求导向、综合性、可扩展性、可靠性、易用性和成本效益等多方面最适合
          的行业解决方法，
          <br />
          切实解决客户真实痛点。
        </p>
      </div>

      <div class="w contents">
        <div class="content">
          <div class="content_head">科研系统经费审核的退回</div>
          <div class="content_main">
            <p>
              直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍
              电商介绍直电商介绍
            </p>
          </div>
        </div>

        <div class="content">
          <div class="content_head">科研系统经费审核的退回</div>
          <div class="content_main">
            <p>
              直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍直播电商介绍
              电商介绍直电商介绍
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <div class="technology">
      <img src="@/assets/imgs/newHome/technology.png" alt="" />
    </div>

    <div class="client">
      <div class="heads">
        <p class="title">客户信任</p>
        <p class="subtitle">
          恒速科技形成以诚信、专业、质量、服务的原则，并不断提升自身的专业素养、服务质量和产品质量，
          <br />
          致力于为客户提 供适宜的解决方案，获得了各行业中不同客户的信任。
        </p>
      </div>
      <div class="w contents">
        <img src="@/assets/imgs/newHome/client.png" alt="" />
      </div>
    </div>

    <div class="contact">
      <div class="heads">
        <p class="title">发起需求</p>
        <p class="subtitle">收到您填写的信息后，我们会通过手机或邮件与您联系</p>
      </div>
      <div class="contents">
        <el-form :model="messageLists" ref="messageRef" :rules="messageRules">
          <div class="mobile">
            <el-form-item prop="email">
              <el-input
                v-model="messageLists.email"
                placeholder="请输入您的邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item prop="phone">
              <el-input
                v-model="messageLists.phone"
                placeholder="请输入您的联系方式"
              ></el-input>
            </el-form-item>
          </div>

          <el-col>
            <el-form-item prop="remarks">
              <el-input
                resize="none"
                :autosize="{ minRows: 4, maxRows: 8 }"
                v-model="messageLists.remarks"
                placeholder="留下您的需求，获取免费解决方案或报价信息（选填）"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <el-button type="primary" class="btn" @click="handleSend('messageRef')"
          >发送</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import {
  findAdById,
  findBanner,
  findBannerItem,
  findCompany,
  getCooperativePartner,
  findItemDetails,
  getCaseList
} from '../../api/home'
import AOS from 'aos'
import Swiper from 'swiper'
import { addMessage } from '../../api/contact'
import '@/assets/css/swiper.min.css'
export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      const regEmail =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
      if (regEmail.test(value)) {
        return callback()
      }
      callback(new Error('请输入合格的邮箱'))
    }
    var checkMobile = (rule, value, callback) => {
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57])[0-9]{8}$/
      if (regMobile.test(value)) {
        return callback()
      }
      callback(new Error('请输入合格的手机号'))
    }

    return {
      caseIndex: 0,
      CaseList: [], //所有项目
      testUrl: require('../../assets/imgs/Home/test.png'),
      cesi: false,
      center1: false,
      bannerImg: [],
      bannerIndex: '',

      staticProducts: [
        {
          id: 1,
          projectName: '掌邻帮帮',
          desc: '我们的项目案例我们的项目案例，我们的项目案例我们的项目案例我们的项目案例我们的项目，案例我们的项目案例我们的项目案例我们的项目案例我们的项目案例我们的项目案例我，们的项目案例我们的项目案例我们的项目案例我们，的项目案例我们的项，目案例我们的项目案例我们的项目案例，我们的项目案例我，们的项目案例我们的项目案例我们的项目案例我们的项目，案例我们的项目。',
          projectDetailImg: require('@/assets/imgs/newHome/bb.png'),
          path: '/substitution'
        },
        {
          id: 2,
          projectName: '科研项目',
          desc: '我们的项目案例我们的项目案例，我们的项目案例我们的项目案例我们的项目案例我们的项目，案例我们的项目案例我们的项目案例我们的项目案例我们的项目案例我们的项目案例我，们的项目案例我们的项目案例我们的项目案例我们，的项目案例我们的项，目案例我们的项目案例我们的项目案例，我们的项目案例我，们的项目案例我们的项目案例我们的项目案例我们的项目，案例我们的项目。',
          projectDetailImg: require('@/assets/imgs/newHome/keyan.png'),
          path: '/keyan'
        }
      ],
      // 核心产品
      coreProducts: [],
      canList: [
        {
          id: 1,
          url: require('@/assets/imgs/newHome/steps/kaifa.png'),
          desc: '策划-设计-开发-测试',
          name: '四步全定制开发'
        },
        {
          id: 2,
          url: require('@/assets/imgs/newHome/steps/hangye.png'),
          desc: '多元化案例开发经验',
          name: '覆盖到各行各业'
        },
        {
          id: 3,
          url: require('@/assets/imgs/newHome/steps/tuiguang.png'),
          desc: '互联网投资和广告接入平台',
          name: '产品分享与推广'
        },
        {
          id: 4,
          url: require('@/assets/imgs/newHome/steps/baozhang.png'),
          desc: '产品适配及功能优化升级',
          name: '保障与升级'
        }
      ],
      doList: [
        {
          id: 1,
          desc: '高端网站/h5定制开发 分销/电商系统定制开发 直播/教育系统定制开发 更多定制服务咨询客服',
          name: '网页定制',
          url: require('@/assets/imgs/newHome/classify/wangzhan.png'),
          hoverUrl: require('@/assets/imgs/newHome/classify/wangzhan_bai.png')
        },
        {
          id: 2,
          desc: '城市产业链 旅游大数据 地图/监控/预测 更多定制服务咨询客服',
          name: '可视化大屏幕',
          url: require('@/assets/imgs/newHome/classify/keshihua.png'),
          hoverUrl: require('@/assets/imgs/newHome/classify/keshihua_bai.png')
        },
        {
          id: 3,
          desc: '外卖零售 餐饮外卖 本地生活/新闻热线 更多定制服务咨询客服',
          name: '微信小程序定制',
          url: require('@/assets/imgs/newHome/classify/xiaochengxu.png'),
          hoverUrl: require('@/assets/imgs/newHome/classify/xiaochengxu_bai.png')
        },
        {
          id: 4,
          desc: '综合商城 金融交易/生活服务 新闻资讯/智能设备 更多定制服务咨询客服',
          name: 'APP定制',
          url: require('@/assets/imgs/newHome/classify/app.png'),
          hoverUrl: require('@/assets/imgs/newHome/classify/app_bai.png')
        }
      ],
      industry: [
        {
          id: 1,
          desc: '提供稳定的直播技术、完善的电商功能、社交功能、数据分析和客服支持的行业解决办法',
          name: '直播电商',
          url: require('@/assets/imgs/newHome/industry/dianshang.png')
        },
        {
          id: 2,
          desc: '提供稳定的快递物流跟踪、完善的下单和预约功能、退换货功能、数据分析和客服支持的行业解决办法',
          name: '快递物流',
          url: require('@/assets/imgs/newHome/industry/wuliu.png')
        },
        {
          id: 3,
          desc: '提供具备稳定的新闻资讯采集、完善的分类和推荐功能、搜索和分享功能、数据分析和客服支持的解决办法',
          name: '新闻资讯',
          url: require('@/assets/imgs/newHome/industry/xinwen.png')
        },
        {
          id: 4,
          desc: '提供具备完善的汽车展示、预约试驾、车辆订购和交付、金融服务、售后服务、客户管理和数据分析的解决办法',
          name: '汽车直销',
          url: require('@/assets/imgs/newHome/industry/zhixiao.png')
        },
        {
          id: 5,
          desc: '提供具备酒店预订、景点门票预订、租车服务、导游服务、旅游攻略、用户评价、数据分析和客服支持的解决办法',
          name: '旅游住宿',
          url: require('@/assets/imgs/newHome/industry/lvyou.png')
        },
        {
          id: 6,
          desc: '提供任务管理、日程管理、文件管理、项目管理、通讯协作、客户管理、数据分析和安全性保障的解决办法',
          name: '办公管理',
          url: require('@/assets/imgs/newHome/industry/bangong.png')
        },
        {
          id: 7,
          desc: '提供会计科目设置、记账功能、财务报表、税务管理、银行对账、多人协作、数据分析和安全性保障的解决办法',
          name: '财务记账',
          url: require('@/assets/imgs/newHome/industry/caiwu.png')
        },
        {
          id: 8,
          desc: '提供科研经费管理、科研成果管理、项目管理、数据分析与可视化、协作和安全性保障的解决办法',
          name: '科研平台',
          url: require('@/assets/imgs/newHome/industry/keyan.png')
        }
      ],

      messageLists: {
        remarks: '',
        email: '',
        phone: ''
      },
      messageRules: {
        // remarks: [
        //   { required: true, message: "请输入留言内容", trigger: "blur" },
        // ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' }
          // { validator: checkEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // {
          //   validator: checkMobile,
          //   trigger: 'blur'
          // }
        ]
      },
      bannerItem: [],
      // 区域二
      cont2TextBox: [
        {
          isActive: true,
          id: '1',
          name: '基础研究',
          contText:
            '基础研究，是对新理论、新原理的探讨，目的在于发现新的科学领域，为新的技术发明和创造提供理论前提'
        },
        {
          isActive: false,
          id: '2',
          name: '应用研究',
          contText:
            '应用研究，是对新理论、新原理的探讨，目的在于发现新的科学领域，为新的技术发明和创造提供理论前提'
        },
        {
          isActive: false,
          id: '3',
          name: '开发研究',
          contText:
            '开发研究，是对新理论、新原理的探讨，目的在于发现新的科学领域，为新的技术发明和创造提供理论前提'
        }
      ],
      // 区域六
      CooperativePartnerImgs: [
        { value: 'ContentBox5Img1' },
        { value: 'ContentBox5Img2' },
        { value: 'ContentBox5Img3' },
        { value: 'ContentBox5Img4' },
        { value: 'ContentBox5Img5' },
        { value: 'ContentBox5Img6' },
        { value: 'ContentBox5Img7' },
        { value: 'ContentBox5Img8' }
      ],
      // 销售联系
      salesContact: [],
      companyTel: '', //公司联系电话
      qrCode: '', //二维码
      currentSwiperIndex: 0, //当前轮播图索引
      mySwiper: null,
      isHover: false,
      hoverIndex: 0
    }
  },
  created() {
    this._initData()
    console.log('process.env', process.env.NODE_ENV)
  },
  mounted() {
    AOS.init({
      offset: 200,
      duration: 600, //持续时间
      easing: 'ease-in-sine',
      delay: 100
    })

    // 移除 鼠标移入 轮播图无法轮播的原因
    this.$refs.carousels.handleMouseEnter = () => {}
  },
  methods: {
    handleToCUstomCase() {
      this.$router.push('/programs')
    },
    handleMouseEnter(index) {
      this.hoverIndex = index
      this.isHover = true
    },
    handleMouseLeave(index) {
      this.hoverIndex = -1
      this.isHover = false
    },
    handleSend(formName) {
      let message = JSON.parse(JSON.stringify(this.messageLists))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addMessage(message).then(() => {
            this.$refs[formName].resetFields()
            this.$message({
              type: 'success',
              message: '添加留言成功'
            })
          })
        } else {
          this.$message({
            type: 'info',
            message: '请填写完整信息'
          })
        }
      })
    },
    go() {
      this.$router.push({
        path: `/details/产品分类/${this.CaseList[0].groupId}/${this.CaseList[0].id}`
      })
    },
    _initData() {
      // 查询首页信息
      findAdById({ classifyId: '-1' }).then((res) => {
        if (res.status == 200) {
          this.bannerImg = res.data
        }
      })
      // 查询所有展示项目
      getCaseList({
        page: 1,
        size: 999,
        start: 0
      }).then((res) => {
        if (res.status == 200) {
          // this.CaseList = res.data.rows;
          console.log('asdasd', res.data.rows)
          this.coreProducts = res.data.rows
        }
      })
      // 查询公司信息
      findCompany().then((res) => {
        if (res.status == 200) {
          this.salesContact = res.data.preSale
            ? JSON.parse(res.data.preSale)
            : ''
          this.companyTel = res.data.afterSale ? res.data.afterSale : ''
          this.qrCode = res.data.qrCode ? res.data.qrCode : ''
        }
      })

      // 获取合作伙伴
      getCooperativePartner().then((res) => {
        if (res.status == 200) {
          this.CooperativePartnerImgs = res.data.cooperativePartnerList
        }
      })
    },
    caseMove(index = 1) {
      console.log(index)
      let swiperPEl = document.getElementsByClassName('.swiper-pagination')[0]
      console.log(swiperPEl)
    },
    mouseenterItem(index) {
      this.cont2TextBox.forEach((item) => {
        if (item.id == index) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
    },

    exhibitionBoxChange(val) {
      if (val == '') {
        this.activeName = ['1']
      }
      this.contentBox1_2.textBox.forEach((item) => {
        item.isActive = item.name == val ? true : false
        return false
      })
    },
    // banner子项目跳转
    goSubpag(item) {
      this.$router.push({
        path: `/details/产品分类/${item.groupId}/${item.id}`
      })
      // this.$router.push({ path: `/subPage/${item.groupId}/${item.id}` });
    },

    swiperChange(index) {
      this.currentSwiperIndex = index
    },

    handleChangeSwiper(index) {
      this.$nextTick(() => {
        this.$refs.carousels.setActiveItem(index)
      })
    },
    handleSwiperChange(type) {
      switch (type) {
        case 'left':
          this.$refs.carousel.prev()
          break
        case 'right':
          this.$refs.carousel.next()
          break
        default:
          break
      }
    },

    handleToDetail(item) {
      this.$router.push({
        path: `${item.path}`
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bannerBox {
  width: 100vw;
  height: 100vh;
  position: relative;
  .circle-dots {
    position: absolute;
    right: 180px;
    bottom: 100px;
    z-index: 2;
    display: flex;
    .circle-dot {
      cursor: pointer;
      width: 14px;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.6);
      margin-right: 10px;
      border-radius: 4px;
      transition: all 0.3s linear;
      &.active {
        width: 40px;
        border-radius: 4px;
        -ms-transform: skew(-10deg); /* IE 9 */
        transform: skew(-10deg); /* Standard syntax */
        background-color: #fff;
      }
    }
  }

  /deep/.el-carousel__container {
    width: 100vw;
    height: 100vh;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
    h2 {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 10%;
      font-size: 120px;
      font-weight: 700;
      color: #fff;
    }
  }

  /deep/.el-carousel__arrow {
    width: 60px;
    height: 60px;
    font-size: 36px;
    border: 1px solid #fff;
  }
}
.bannerBox > .item {
  width: 930px;
  height: 158px;
  position: absolute;
  bottom: -30px;
  left: 50%;
  margin-left: -465px;
  box-sizing: border-box;
  z-index: 2;
}
.bannerBox {
  .item {
    .itemTop {
      display: flex;
      padding-left: 95px;
      box-sizing: border-box;
      height: 60px;
      /deep/.el-menu.el-menu--horizontal {
        border-bottom: 0 !important;
      }

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 53px;
        color: #ffffff;
        opacity: 0.8;
        margin-right: 26px;
      }
      .el-menu-demo {
        width: 100%;
        display: flex;
        flex-wrap: no-wrap;
        overflow-x: scroll;
        scrollbar-width: none;
        /deep/.el-menu-item {
          margin-right: 47px;
          font-size: 22px;
          opacity: 0.8;
          height: 40px;
          line-height: 40px;
          transition: all 0.3s linear;
        }
        /deep/.el-menu-item.is-active {
          font-size: 24px;
          font-weight: 700;
          opacity: 1;
        }
        /deep/.el-menu-item:hover {
          background-color: transparent !important;
        }
      }
    }
    .itemBottoms {
      width: 100%;
      padding: 18px 32px 12px;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: 4px 4px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      transition: all 0.5s linear;
      .left {
        width: 225px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 32px;
          color: #02548c;
          margin-bottom: 4px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 18px;
          color: #c2c6cc;
        }
      }
      .center {
        // min-height: 88px;
        // max-height: 88px;
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        margin: 0 34px 0 30px;
        overflow: hidden;
        transition: all 0.5s linear;
        //  transition: max-height ease-out 0.2s;
        .item {
          margin: 0 10px 10px 0;
          width: 160px;
          // padding: 10px 0;
          box-sizing: border-box;
          text-align: left;
          cursor: pointer;
          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            // line-height: 21px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            > a {
              color: #484848;
            }
          }
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;
            color: #c2c6cc;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &.nodata {
          font-size: 22px;
          width: 100%;
          text-align: center;
          align-content: center;
        }
      }

      .right {
        width: 54px;
        height: 54px;

        text-align: center;
        line-height: 54px;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transform: rotateZ(180deg);
        transition: all linear 0.2s;

        &.bannerIcon {
          transform: rotateZ(90deg);
        }
      }
    }
  }
}

.steps {
  width: 100%;
  height: 884px;
  .steps_head {
    width: 100%;
    height: 120px;
    background-color: #fff;
    .steps_head-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .steps_head-card {
        border-radius: 16px;
        width: 24%;
        height: 100px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-right: 20px;
          object-fit: cover;
        }
        .steps_head-card-content {
          text-align: left;
          .name {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 36px;
            text-align: left;
            color: #2a2f32;
          }
          .desc {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 36px;
            color: #959ea4;
          }
        }
      }
    }
  }
  .steps_content {
    height: calc(884px - 160px);
    background-color: #f5f9fc;
    padding: 70px 0;
    box-sizing: border-box;
    .heads {
      margin-bottom: 36px;
      h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #2a2f32;
        margin-bottom: 8px;
      }
      p {
        font-style: normal;
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #7f8c94;
        margin-top: 28px;
      }
    }
    .steps_content-cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .steps_content-card {
        width: 24%;
        height: 360px;
        background: #fff;
        border-radius: 16px;
        padding: 40px 30px;
        box-sizing: border-box;
        transition: all 0.3s linear;

        img {
          width: 48px;
          height: 48px;
          border-radius: 4px;
          object-fit: cover;
        }
        .name {
          margin: 16px 0 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #2a2f32;
        }
        .desc {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          color: #959ea4;
          margin: 25px 0;
          letter-spacing: 1px;
        }

        &:hover {
          cursor: pointer;
          background-color: #2474ea;
          .name,
          .desc {
            color: #fff;
          }
        }
      }
    }
    .more {
      width: 126px;
      height: 40px;
      background: #0569ff;
      border-radius: 63px;
      font-size: 15px;
      line-height: 24px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.2em;
      color: #ffffff;
      border: 0;
      margin-top: 40px;
      transition: all 0.3s linear;
      &:hover {
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }
}
.productBox {
  width: 100%;
  height: 758px;
  padding: 70px 0;
  box-sizing: border-box;
  .heads {
    margin-bottom: 60px;
    .title {
      font-size: 28px;
      font-family: 'OPPOSans-B';
      font-weight: 700;
      text-align: center;
      color: #2a2f32;
      line-height: 18px;
      margin-bottom: 10px;
      margin-bottom: 8px;
    }
    .subtitle {
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #7f8c94;
      margin-top: 28px;
    }
  }
  .productBox_parts {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .part {
      width: 5%;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
        transition: all 0.3s linear;

        &:hover {
          cursor: pointer;
          transform: scale(1.2);
        }
      }
    }
    .center {
      margin: 0 30px;
      flex: 1;
      height: 45vh;
      border-radius: 16px;
      background-color: #f5f9fc;
      /deep/.el-carousel,
      /deep/.el-carousel__container {
        height: 100%;
      }
      /deep/.el-carousel__item {
        height: 100%;
      }

      .carousel {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 50px;
        box-sizing: border-box;
        justify-content: space-between;

        .carousel_left {
          flex: 1;
          margin-right: 60px;
          text-align: left;
          h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            color: #2a2f32;
          }
          .dots {
            display: flex;
            align-items: center;
            margin: 10px 0;
            .carousel_dots {
              margin-right: 4px;
              display: flex;
              align-items: center;
              .carousel_dot {
                transform: skewX(20deg);
                width: 3px;
                height: 6px;
                background-color: #adc2fc;
                margin: 0 2px 0 0;
              }
            }
          }

          .carousel_desc {
            // height: 150px;
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            color: #959ea4;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
          }
          .carousel_btn {
            font-size: 15px;
            box-sizing: border-box;
            text-align: center;
            border: 2px solid #0569ff;
            color: #0569ff;
            font-weight: 400;
            width: 100px;
            height: 32px;
            line-height: 28px;
            border-radius: 50px;
            transition: all 0.3s linear;
            letter-spacing: 0.2em;
            &:hover {
              cursor: pointer;
              transform: scale(1.1);
            }
          }
        }

        .carousel_right {
          width: 400px;
          height: 300px;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
.industry {
  width: 100%;
  height: 628px;
  padding: 70px 0;
  background-color: #f5f9fc;
  box-sizing: border-box;
  .heads {
    margin-bottom: 36px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
      margin-bottom: 8px;
    }
    .subtitle {
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #7f8c94;
      margin-top: 28px;
    }
  }
  .contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .content {
      width: 280px;
      height: 170px;
      background-color: #fff;
      margin-bottom: 20px;
      border-radius: 16px;
      padding: 20px;
      box-sizing: border-box;
      .content-top {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        .content-top_left {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .content-top_right {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          color: #2a2f32;
        }
      }
      .content-bottom {
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #959ea4;
      }
    }
  }
}
.scheme {
  width: 100%;
  height: 548px;
  padding: 70px 0;
  box-sizing: border-box;
  background-color: #fff;
  .heads {
    margin-bottom: 36px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
      margin-bottom: 8px;
    }
    .subtitle {
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #7f8c94;
      margin-top: 28px;
    }
  }
  .contents {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .content {
      width: 560px;
      height: 260px;
      border: 1px solid #d7dfea;
      border-radius: 16px;
      overflow: hidden;
      .content_head {
        width: 100%;
        height: 50px;
        background: #d7dfea;
        padding-left: 40px;
        box-sizing: border-box;
        text-align: left;
        line-height: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #2a2f32;
      }
      .content_main {
        padding: 24px 40px;
        box-sizing: border-box;
        text-align: left;
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 24px;
          color: #959ea4;
          margin-bottom: 24px;
        }
        .btn {
          width: 92px;
          height: 32px;
          left: 817px;
          top: 3547px;
          background: #fff;
          border: 1px solid #0569ff;
          border-radius: 46px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          text-align: center;
          line-height: 24px;
          color: #0569ff;
          transition: all 0.3s linear;
          &:hover {
            transform: scale(1.1);
            cursor: pointer;
          }
        }
      }
    }
  }
}
.technology {
  width: 100%;
  height: 262px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.client {
  width: 100%;
  padding: 70px 0;
  box-sizing: border-box;
  .heads {
    margin-bottom: 36px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
      margin-bottom: 8px;
    }
    .subtitle {
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #7f8c94;
      margin-top: 28px;
    }
  }
}
.contact {
  width: 100%;
  height: 500px;
  padding: 70px 0;
  background-color: #f5f9fc;
  box-sizing: border-box;
  .heads {
    margin-bottom: 36px;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      text-align: center;
      color: #2a2f32;
      margin-bottom: 8px;
    }
    .subtitle {
      font-style: normal;
      font-weight: 200;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #7f8c94;
      margin-top: 28px;
    }
  }

  .contents {
    width: 800px;
    margin: 0 auto;
    .mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-form-item {
        width: 48%;
      }
    }
  }
}
</style>
